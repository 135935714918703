body {
  font-family: 'Noto Sans', sans-serif; /* 선택한 폰트로 설정 */
  font-size: 16px; /* 기본 글자 크기 */
  color: #333; /* 기본 글자 색상 */
}

.qna-page {
    background-image: url('../images/backgroundImg.jpg'); /* 메인 페이지와 동일한 이미지 사용 */
    background-size: contain; /* 이미지가 컨테이너를 완전히 덮도록 설정 */
    background-position: center center; /* 이미지가 컨테이너의 중앙에 위치하도록 설정 */
    background-attachment: fixed; /* 배경 이미지가 스크롤 시 고정되도록 설정 */margin: 0; /* 여백을 제거하여 일관된 배경을 유지 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0; /* 여백을 제거하여 일관된 배경을 유지 */
    padding: 0; /* 패딩을 제거하여 일관된 배경을 유지 */
    overflow: hidden;
  }
  
  .page-title {
    font-size: 2rem;
    margin-left: 300px;
    font-weight: bold;
    color: #00796B;
    margin-bottom: 30px;
  }
  
  .search-bar {
    float: right; /* 오른쪽 상단으로 검색바 위치 */
    width: 250px; /* 검색바 크기를 줄임 */
    margin-bottom: 10px; /* 게시판과의 간격 */
  }
  
  .custom-search {
    display: flex;
    border: 1px solid black !important;
    overflow: hidden;
    height: 40px; /* 검색 바 높이를 줄임 */
    top : 50px;
  }
  
  .search-bar textarea {
    height: 100%; /* 입력 필드가 부모 높이에 맞게 확장되도록 */
    resize: none; /* 텍스트 영역의 크기 조절 비활성화 */
    padding: 5px 10px; /* 내부 여백 조정 */
    font-size: 14px; /* 글자 크기를 줄여서 전체적으로 크기 줄이기 */
    border: none;
  }
  
  
  .qna-table th {
    background-color: #80CBC4; /* 테이블 헤더 배경색 */
    color: white;
    text-align: center;
    padding: 12px;
  }
  
  .qna-table td {
    text-align: center;
    padding: 15px;
  }
  
  /* 제목 열 길이 조정 */
  .qna-table th:nth-child(1),
  .qna-table td:nth-child(1) {
    width: 60%; /* 제목 열 길이 */
  }
  
  /* 작성자 열 길이 조정 */
  .qna-table th:nth-child(2),
  .qna-table td:nth-child(2) {
    width: 20%; /* 작성자 열 길이 */
  }
  
  /* 작성일 열 길이 조정 */
  .qna-table th:nth-child(3),
  .qna-table td:nth-child(3) {
    width: 20%; /* 작성일 열 길이 */
  }
  
  /* 링크 스타일 수정 */
  .qna-table a {
    color: inherit; /* 링크의 기본 색상 제거 */
    text-decoration: none; /* 링크의 밑줄 제거 */
  }
  
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination .page-item .page-link {
    color: #00796B; 
    padding: 10px 15px;
    border-radius: 50%; /* 둥근 페이지네이션 버튼 */
    border: none;
    margin: 0 5px;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #83C9E7;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .write-button-container .btn {
    font-size: 1.2rem;
    padding: 12px 24px;
    background: linear-gradient(90deg, #80CBC4, #83C9E7);
    color: white;
    border-radius: 25px;
    transition: transform 0.2s;
  }
  
  .write-button-container .btn:hover {
    transform: scale(1.05); 
  }
  
  /* 게시판 박스 상단과 우측에 붙는 스타일 */
  .container-box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: 90%; 
    max-width: 1200px;
    position: relative; /* 검색바 위치 조정용 */
  }
  
  .qna-page .search-bar {
    position: absolute;
    top: 140px; /* 게시판 상단과 검색바 간격 */
    right: 10px; /* 오른쪽에 붙임 */
  }

  .search-bar-container {
    display: flex;
    justify-content: flex-end; /* 검색바를 오른쪽에 배치 */
    margin-bottom: 20px; /* 아래 여백 추가 */
  }
  
  .search-bar-container .form-control {
    border-radius: 0;
    border: 2px solid #00796B; /* 테두리 색상 */
    padding: 10px;
  }
  
  .search-bar-container .btn {
    border-radius: 0;
    background-color: #00A3E0; /* 버튼 배경색 */
    color: white;
    padding: 0 15px;
    border: none;
  }
  
  .search-bar-container .btn:hover {
    background-color: #00796B; /* 버튼 호버 시 색상 변경 */
  }
  
  