.search-medicine-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: white;
  background-image: url('../images/backgroundImg.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
}

.search-bar-container {
  position: absolute;
  top: 100px;
  right: 20px; /* 오른쪽 여백 추가 */
  display: flex;
  justify-content: flex-end;
  align-items: center; /* 수직 정렬 */
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8); /* 반투명 배경 */
  border-radius: 8px; /* 모서리 둥글게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
}

.search-bar-container form {
  display: flex;
  align-items: center; /* 수직 정렬 */
}

.search-bar-container .form-control {
  border: 1px solid #ddd; /* 테두리 색상 */
  border-radius: 8px; /* 모서리 둥글게 */
  padding: 10px;
  font-size: 1.1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* 내부 그림자 */
  transition: border-color 0.3s ease;
  border: 2px solid #00A3E0;
}

.search-bar-container .form-control:focus {
  border-color: #83C9E7; /* 포커스 시 테두리 색상 */
  box-shadow: 0px 0px 10px rgba(0, 163, 224, 0.5); /* 포커스 시 그림자 */
}

.search-bar-container button {
  background-color: #4caf50; /* 버튼 배경 색상 */
  color: white; /* 버튼 텍스트 색상 */
  border: none;
  border-radius: 8px; /* 모서리 둥글게 */
  padding: 10px 20px;
  font-size: 1.1rem;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.search-bar-container button:hover {
  background-color: #45a049; /* 버튼 호버 시 배경 색상 */
}

.search-bar-container button:focus {
  outline: none; /* 포커스 시 기본 테두리 제거 */
}


.card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 200px;
  width: 100%;
  max-width: 1200px;
  margin-left: 60px;
  max-height: 600px; 
  gap: 20px;
  padding-top: 40px;
  overflow-y: auto; 
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 100%); /* 예쁜 그라데이션 배경 */
  border-radius: 16px; /* Smoother rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Stronger shadow */
}


.card-col {
  flex: 0 0 30%;
  margin-bottom: 20px;
  margin-left: 20px;
}

.card {
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-10px); /* Higher hover translation */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
  border: 2px solid #1e90ff; /* Slight blue border on hover */
}

.card-img {
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; /* Rounds the top edges */
}

.card-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #2c3e50; /* Darker and more elegant */
  padding: 0.5rem 0; /* Adds space between the title and content */
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
}

.modal-body {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  max-height: 60vh;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.medicine-text {
  font-size: 1.2rem; /* 글씨 크기 */
  color: #333; /* 글씨 색상 */
  line-height: 1.6; /* 줄 간격 */
  font-weight: 400; /* 글씨 두께 */
  text-shadow: none;
}

.modal-content {
  border-radius: 12px; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 그림자 */
  border: none; /* 테두리 제거 */
  overflow: hidden; /* 내부 내용이 모달 경계를 넘지 않도록 */
}

.modal-header {
  background-color: #83C9E7; /* 헤더 배경 색상 */
  color: white; /* 헤더 텍스트 색상 */
  border-bottom: 1px solid #ddd; /* 하단 테두리 */
  padding: 20px;
}

.modal-title {
  font-size: 1.5rem; /* 제목 크기 */
  font-weight: bold; /* 제목 두께 */
}

.modal-body {
  padding: 20px;
  background-color: #f9f9f9; /* 본문 배경 색상 */
  max-height: 60vh; /* 최대 높이 */
  overflow-y: auto; /* 스크롤 가능 */
}

.modal-footer {
  border-top: 1px solid #ddd; /* 상단 테두리 */
  padding: 10px 20px;
  background-color: #f9f9f9; /* 푸터 배경 색상 */
  display: flex;
  justify-content: flex-end; /* 버튼을 오른쪽에 정렬 */
}

.modal-footer button {
  border-radius: 8px; /* 버튼 모서리 둥글게 */
  padding: 10px 20px;
  font-size: 1rem; /* 버튼 텍스트 크기 */
  margin-left: 10px;
  background-color: #83C9E7; /* 버튼 배경 색상 */
  color: white; /* 버튼 텍스트 색상 */
  border: none;
  transition: background-color 0.3s ease;
}

.modal-footer button:hover {
  background-color: #83C9E7; /* 버튼 호버 시 배경 색상 */
}

.modal-body .list-group-item {
  border-radius: 8px; /* 항목 모서리 둥글게 */
  margin-bottom: 5px; /* 항목 간 간격 */
  background-color: #ffffff; /* 항목 배경 색상 */
  transition: background-color 0.3s ease;
}

.modal-body .list-group-item:hover {
  background-color: #e8f5e9; /* 호버 시 배경 색상 */
}

.modal-body .list-group {
  border-radius: 12px; /* 메뉴바 모서리 둥글게 */
  background-color: #ffffff; /* 배경 색상 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 그림자 */
  border: 1px solid #ddd; /* 테두리 색상 */
  overflow: hidden; /* 내부 내용이 경계를 넘지 않도록 */
}

/* 메뉴 항목 스타일 */
.modal-body .list-group-item {
  border-radius: 8px; /* 항목 모서리 둥글게 */
  margin-bottom: 5px; /* 항목 간 간격 */
  padding: 10px 15px; /* 패딩 */
  font-size: 1rem; /* 폰트 크기 */
  color: #333; /* 텍스트 색상 */
  background-color: #ffffff; /* 항목 배경 색상 */
  transition: background-color 0.3s ease, color 0.3s ease; /* 배경 색상과 텍스트 색상 전환 */
}

/* 메뉴 항목 호버 스타일 */
.modal-body .list-group-item:hover {
  background-color: #83C9E7; /* 호버 시 배경 색상 */
  color: black; /* 호버 시 텍스트 색상 */
}

/* 선택된 메뉴 항목 스타일 */
.modal-body .list-group-item.active {
  background-color: #83C9E7; /* 선택된 항목 배경 색상 */
  color: black; /* 선택된 항목 텍스트 색상 */
  font-weight: bold; /* 선택된 항목 텍스트 두께 */
}
/* 내용 스타일 */
.modal-body p {
  font-size: 1.1rem; /* 기본 폰트 크기 */
  color: #333; /* 글자 색상 */
  line-height: 1.8; /* 줄 간격 */
  margin: 0 0 15px 0; /* 상단 여백 제거, 하단 여백 추가 */
  padding: 0; /* 패딩 제거 */
  text-align: left; /* 텍스트 정렬 왼쪽 */
  font-family: 'Roboto', sans-serif; /* 폰트 패밀리 설정 */
  text-shadow: none; /* 글자 그림자 제거 */
  background-color: #f9f9f9; /* 배경 색상 추가 */
  border-radius: 4px; /* 모서리 둥글게 */
  padding: 15px; /* 내용 내 여백 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 추가 */
}
/* 선택된 메뉴 항목 스타일 */
.modal-body .list-group-item.active {
  background-color: #83C9E7; /* 선택된 항목 배경 색상 */
  color: black; /* 선택된 항목 텍스트 색상 */
  font-weight: bold; /* 선택된 항목 텍스트 두께 */
}
