body {
  font-family: 'Noto Sans', sans-serif; /* 선택한 폰트로 설정 */
  font-size: 16px; /* 기본 글자 크기 */
  color: #333; /* 기본 글자 색상 */
}

.qna-page{
  background-image: url('../images/backgroundImg.jpg'); /* 메인 페이지와 동일한 이미지 사용 */
    background-size: cover; /* 이미지가 컨테이너를 완전히 덮도록 설정 */
    background-position: center center; /* 이미지가 컨테이너의 중앙에 위치하도록 설정 */
    background-attachment: fixed; /* 배경 이미지가 스크롤 시 고정되도록 설정 */margin: 0; /* 여백을 제거하여 일관된 배경을 유지 */
    min-height: 100vh;
}
.container-box {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;
  max-width: 800px; /* 박스의 최대 너비를 설정 */
}

.page-title {
  font-size: 2rem;
  color: #333; /* 제목 색상 */
  margin-bottom: 20px;
  text-align: center;
}


.write-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.write-button-container .btn {
  font-size: 1rem;
  padding: 10px 20px;
  margin-left: 10px;
}

.write-button-container .btn-secondary {
  background-color: #f1f1f1; /* 목록보기 버튼 배경 색상 */
  color: #333; /* 텍스트 색상 */
  border: none;
}

.write-button-container .btn-secondary:hover {
  background-color: #e0e0e0; /* 호버 시 배경 색상 */
}

.write-button-container .btn-success {
  background-color: #4caf50; /* 등록 버튼 배경 색상 */
  color: white; /* 텍스트 색상 */
  border: none;
}

.write-button-container .btn-success:hover {
  background-color: #45a049; /* 호버 시 배경 색상 */
}

input, textarea {
  border-radius: 5px; /* 입력 필드 둥근 모서리 */
}

textarea {
  resize: none; /* 텍스트 영역 크기 조절 비활성화 */
}

.form-control {
  border: 1px solid #ccc; /* 입력 필드 테두리 색상 */
}
