.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 56px); /* 내비바 높이 제외 */
    background-image: url('../images/backgroundImg.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-top: 56px; /* 내비바 높이 */
    overflow: hidden;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 90%; /* 너비 조정 */
}


.search-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 340px; /* 제목과 검색창 간의 여백 */
}

.form-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.form-container .form-control {
    height: 80px; /* 조정 필요 */
    border-radius: 30px; /* 둥근 모서리 */
    padding: 0 20px; /* 여백 조정 */
    font-size: 2rem;
    text-align: center;
    box-sizing: border-box;
    border: 2px solid #00A3E0;
}

.form-container .form-control:focus {
    border-color: #83C9E7;
    box-shadow: 0px 0px 10px rgba(0, 163, 224, 0.5);
}

.form-container .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.content-container h1 {
    font-size: 2.5rem; /* 제목 크기 조정 */
    color: #000000;
    margin-bottom: 20px;
}

p {
    font-size: 2rem; /* 설명 텍스트 크기 조정 */
    color: #000000;
    margin-top: 10px;
}

.form-label {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
}

.form-control {
    padding: 15px;
    font-size: 1.2rem;
    border-radius: 0.25rem;
    border-color: #007bff; /* 파란색 테두리 */
}

.search-container button {
    background: linear-gradient(135deg, #4aadd8, #00A3E0);
    color: white;
    font-size: 1.5rem;
    border: none;
    border-radius: 30px;
    padding: 10px 30px;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 163, 224, 0.4);
}

.search-container button:hover {
    background: linear-gradient(135deg, #00A3E0, #83C9E7);
    box-shadow: 0px 6px 15px rgba(0, 163, 224, 0.6);
    transform: translateY(-3px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.content-container h1,
.content-container p {
    animation: fadeIn 2s ease-in-out;
    color: #003366;
}

