body {
  font-family: 'Noto Sans', sans-serif; /* 선택한 폰트로 설정 */
  font-size: 16px; /* 기본 글자 크기 */
  color: #333; /* 기본 글자 색상 */
}

.main-content {
  padding: 20px;
  padding-top: 140px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.brand-title {
  color: #1E90FF;
  text-shadow: none;
}
